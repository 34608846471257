import Swal from '../sweetalert2/dist/sweetalert2.js'
var __spreadArray = (this && this.__spreadArray) || function(to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++) { to[j] = from[i] }
    return to
}
var VueSweetalert2 = (function() {
    function VueSweetalert2() {
    }
    VueSweetalert2.install = function(vue, options) {
        var _a
        if (options === void 0) { options = {} }
        var swalLocalInstance = Swal.mixin(options)
        var swalFunction = function() {
            var _a
            var args = []
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i]
            }
            return (_a = swalLocalInstance.fire).call.apply(_a, __spreadArray([swalLocalInstance], args))
        }
        Object.assign(swalFunction, Swal)
        var methodName
        for (methodName in Swal) {
            if (Object.prototype.hasOwnProperty.call(swalLocalInstance, methodName) &&
                typeof swalLocalInstance[methodName] === 'function') {
                swalFunction[methodName] = swalLocalInstance[methodName].bind(swalLocalInstance)
            }
        }
        if (((_a = vue.config) === null || _a === void 0 ? void 0 : _a.globalProperties) && !vue.config.globalProperties.$swal) {
            vue.config.globalProperties.$swal = swalFunction
            vue.provide('$swal', swalFunction)
        } else if (!Object.prototype.hasOwnProperty.call(vue, '$swal')) {
            vue.prototype.$swal = swalFunction
            vue.swal = swalFunction
        }
    }
    return VueSweetalert2
}())
export default VueSweetalert2
// # sourceMappingURL=index.js.map
