import session from '../api/session';
import {
    NAVIGATION
} from './types';

const state = {
    navElements: [],
};

const mutations = {
    [NAVIGATION](state, data) {
        state.navElements = data;
    },
};

const actions = {
    initialize({commit}) {
        return session.get(`/api/v1/menu/`)
            .then(({data}) => commit(NAVIGATION, data))
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters: {
        navElements: state => state.navElements,
    },
    actions
};
