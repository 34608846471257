import session from './session';

export default {
    login(email, password, params) {
        return session.post('/api/v1/token/', {email, password}, { params: params});
    },
    logout() {
        return session.post('/api/v1/logout/', {});
    },
    refreshToken(refresh) {
        return session.post('/api/v1/token-refresh/', {refresh: refresh});
    },
    autoLogin(params) {
        return session.post('/api/v1/auth-login/', {}, {params: params});
    },
    changeAccountPassword(password1, password2) {
        return session.post('/api/auth/password/change/', {password1, password2});
    },
    sendAccountPasswordResetEmail(email) {
        return session.post('/api/auth/password/reset/', {email});
    },
    resetAccountPassword(uid, token, new_password1, new_password2) { // eslint-disable-line camelcase
        return session.post('/api/auth/password/reset/confirm/', {uid, token, new_password1, new_password2});
    },
    getAccountDetails() {
        return session.get('/api/auth/user/');
    },
    updateAccountDetails(data) {
        return session.patch('/api/auth/user/', data);
    },
    verifyAccountEmail(key) {
        return session.post('/registration/verify-email/', {key});
    },
};
