import auth from '../api/auth';
import session from '../api/session';
import {
    LOGIN_BEGIN,
    LOGIN_FAILURE,
    LOGIN_SUCCESS,
    LOGOUT,
    REMOVE_TOKEN,
    SET_WIZARD,
    SET_TOKEN,
    SET_PHONE_MASKS
} from './types';

const isAuthorized = 'isAuthorized';
const TOKEN_STORAGE_KEY = 'TOKEN_STORAGE_KEY';
const REFRESH_STORAGE_KEY = 'REFRESH_STORAGE_KEY';
const isProduction = process.env.NODE_ENV === 'production';

const initialState = {
    authenticating: false,
    error: false,
    errors: [],
    token: null,
    refresh: null,
    onWizard: false,
    phoneMasks: []
};

const getters = {
    isAuthenticated: state => {
        return !!state.token;
    },
    token: state => state.token,
    onWizard: state => state.onWizard,
    authErrors: state => state.errors,
    phoneMasks: state => state.phoneMasks
};

const actions = {
    login({commit}, {email, password, params}) {
        commit(LOGIN_BEGIN);
        return auth.login(email, password, params)
            .then(({data}) => {
                console.log('data', data)
                commit(SET_TOKEN, data);
                commit(SET_WIZARD, data);
            })
            .then(() => commit(LOGIN_SUCCESS))
            .catch((error) => {
                commit(LOGIN_FAILURE, error.response.data)
                throw new Error('Server error')
            });
    },
    autoLogin({commit}, {params}) {
        commit(LOGIN_BEGIN);
        return auth.autoLogin(params)
            .then(({data}) => commit(SET_TOKEN, data))
            .then(() => commit(LOGIN_SUCCESS))
            .catch((error) => {
                commit(LOGIN_FAILURE, error.response.data)
                throw new Error('Server error')
            });
    },
    logout({commit}) {
        return auth.logout()
            .then(() => {
                localStorage.removeItem(isAuthorized);
                localStorage.removeItem(TOKEN_STORAGE_KEY);
                localStorage.removeItem(REFRESH_STORAGE_KEY);
                delete session.defaults.headers.Authorization;
                commit(REMOVE_TOKEN);
                commit('knowledge_base/MATERIALS_LIST', [], { root: true });
                commit('default_data/DEFAULT_DATA', {}, {root: true});
            });
    },
    refreshToken({commit}) {
        if (initialState.refresh) {
            return auth.refreshToken(initialState.refresh)
                .then(({data}) => commit(SET_TOKEN, data))
                .catch((error) => {
                    commit(REMOVE_TOKEN);
                    localStorage.removeItem(isAuthorized);
                    localStorage.removeItem(TOKEN_STORAGE_KEY);
                    localStorage.removeItem(REFRESH_STORAGE_KEY);
                    delete session.defaults.headers.Authorization;
                });
        }
    },
    clearToken({commit}) {
        commit(REMOVE_TOKEN);
        localStorage.removeItem(isAuthorized);
        localStorage.removeItem(TOKEN_STORAGE_KEY);
        localStorage.removeItem(REFRESH_STORAGE_KEY);
        delete session.defaults.headers.Authorization;
    },
    initialize({commit}) {
        let access = localStorage.getItem(TOKEN_STORAGE_KEY);
        let data = {
            access: access,
            refresh: localStorage.getItem(REFRESH_STORAGE_KEY)
        };
        if (access) {
            commit(SET_TOKEN, data);
        }
    },
    getPhoneMasks({commit}) {
        return session.get('/api/v1/phone-mask/')
            .then(({data}) => {
                const newData = data.map(mask => ({
                    ...mask,
                    region_code: mask.region_code.toLowerCase(),
                    rules: {
                      ...mask.rules,
                      phone: [
                        ...mask.rules.phone.map(rule => ({...rule, trigger: "blur"}))
                      ]
                    }
                }))
                commit(SET_PHONE_MASKS, newData)
            })
    },
};

const mutations = {
    [SET_WIZARD](state, data) {
        state.onWizard = data.start;
    },
    [LOGIN_BEGIN](state) {
        state.authenticating = true;
        state.error = false;
    },
    [LOGIN_FAILURE](state, errors) {
        state.authenticating = false;
        state.error = true;
        state.errors = errors;
    },
    [LOGIN_SUCCESS](state) {
        state.authenticating = false;
        state.error = false;
    },
    [LOGOUT](state) {
        state.authenticating = false;
        state.error = false;
    },
    [SET_TOKEN](state, data) {
        localStorage.setItem(isAuthorized, true);
        localStorage.setItem(TOKEN_STORAGE_KEY, data.access);
        localStorage.setItem(REFRESH_STORAGE_KEY, data.refresh);
        session.defaults.headers.Authorization = `Token ${data.access}`;
        state.token = data.access;
        state.refresh = data.refresh;
    },
    [REMOVE_TOKEN](state) {
        state.token = '';
        state.refresh = '';
    },
    [SET_PHONE_MASKS](state, data) {
        state.phoneMasks = data;
    }
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
