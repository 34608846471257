import Vue from 'vue';

function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

Vue.axios.defaults.headers = {
    'X-CSRFToken': getCookie('csrftoken'),
    'X-Forwarded-Proto': location.protocol.replace(':', ''),
    'HTTP_X_FORWARDED_PROTO': location.protocol.replace(':', '')
};


// Vue.axios.defaults.onUploadProgress = (progressEvent) => {
//     let percentCompleted =  Math.round((progressEvent.loaded * 100) / progressEvent.total);
//     this.$Progress.set(percentCompleted);
//     return percentCompleted;
// };
