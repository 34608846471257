<template>
    <div
        class="sidebar hide-print-css"
        id="sidebar"
        :style="`--sidebar-left: ${sidebarLeft};`"
        @touchmove="onSidebarChangeWidth"
        @touchcancel="sidebarDragging = false"
    >
        <perfect-scrollbar class="sidebar__main">
            <navigation/>
        </perfect-scrollbar>

        <div class="sidebar__footer">
            <tree-picker/>
            <search/>
        </div>

        <div
            class="sidebar__strip"
            @touchstart="sidebarDragging = true"
        ></div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';

    export default {
      name: 'Sidebar',
      components: {
        TreePicker: () => import('@/components/sidebar/tree/TreePicker'),
        HeaderDropdown: () => import('./header/HeaderDropdown'),
        Navigation: () => import('./navigation/Navigation'),
        Search: () => import('@/components/sidebar/search/Search')
      },

      computed: {
        // Не вздумайте делать отображение через v-if
        ...mapGetters('auth', ['isAuthenticated'])
      },

      data() {
        return {
            sidebarLeft: 'calc(100% - 300px)',
            sidebarDragging: false
        };
      },

      created() {
        if (this.isAuthenticated) {
            this.initSidebarStore();
        }

        this.sidebarLeft = localStorage.getItem('sidebar-left') || 'calc(100% - 300px)';
      },

      watch: {
        isAuthenticated(newValue, oldValue) {
          if (newValue === true) {
            this.initSidebarStore();
          }
        }
      },

      methods: {
        async initSidebarStore() {
            try {
                await Promise.all([
                    this.$store.dispatch('sidebar/initialize'),
                    this.$store.dispatch('knowledge_base/setMaterialsList')
                ]);
            } catch (error) {
                console.error(error);
            }
        },

          onSidebarChangeWidth(event) {
              const touch = event.touches[0];

              if (this.sidebarDragging === true) {
                  if (
                      touch.clientX <= 480 &&
                      touch.clientX >= 0
                  ) {
                      this.sidebarLeft = `${touch.clientX}px`;
                  }

                  if (touch.clientX <= 5) {
                      this.sidebarLeft = '0px';
                  }

                  localStorage.setItem('sidebar-left', this.sidebarLeft);
              }
          }
      }
    };
</script>

<style lang="scss">
    @import "#sass/v-style";

    .sidebar {
        display: flex;
        flex-direction: column;

        @media screen and (min-width: 1001px) {
            width: 100%;
        }

        @media screen and (max-width: 1000px) {
            top: 60px;
            right: 0px;
            bottom: 0px;
            left: var(--sidebar-left);
            position: fixed;

            opacity: 0;
            visibility: hidden;

            background-color: var(--sidebar-bg);
            transform: translateX(50px);
            transition: transform $ease;
            &.is-visible {
                opacity: 1;
                visibility: visible;
                transform: translateX(0px);
            }
        }

        @media screen and (max-width: 600px) {
            left: 0px;
            width: 100%;
        }

        &__header {
            display: none;
        }

        &__main {
            height: 0px;
            padding: 5px;
            flex: 1 0 auto;
        }

        &__strip {
            top: 0px;
            left: 0px;
            bottom: 0px;
            position: absolute;
            user-select: none;
            cursor: col-resize;
            width: 10px;

            @media screen and (min-width: 1001px) {
                display: none;
            }
        }

        .ps__rail-y {
            z-index: 99;
        }
    }
</style>
