import axios from 'axios';
import router from '../router';
import store from '@/store';

const CSRF_COOKIE_NAME = 'csrftoken';
const CSRF_HEADER_NAME = 'X-CSRFToken';

const session = axios.create({
    baseURL: process.env.VUE_APP_URL ? process.env.VUE_APP_URL : process.env.BASE_URL,
    xsrfCookieName: CSRF_COOKIE_NAME,
    xsrfHeaderName: CSRF_HEADER_NAME,
    headers: {
        'X-Forwarded-Proto': location.protocol.replace(':', ''),
        'HTTP_X_FORWARDED_PROTO': location.protocol.replace(':', ''),
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true,
    }
});

let count_error_401 = 0;

// Add a response interceptor
session.interceptors.response.use(
    (response) => {
        if(response.data.code !== 401 && response.config.url !== '/api/v1/token-refresh/') {
            count_error_401 = 0;
        }
        // Do something with response data
        return response;
    },
    async (error) => {
        // Do something with response error
        if (error.config.url === '/api/v1/token-refresh/' && count_error_401 >= 10) {
            console.info('use - login - 1');
            await store.dispatch('auth/clearToken');
            return router.push('/login');
        } else if (error.response && error.response.status === 401 && count_error_401 < 10) {
            count_error_401 += 1;
            await store.dispatch('auth/refreshToken');
            let token = store.getters['auth/token'];
            if (token) {
                return new Promise((resolve, reject) => {
                    error.config.headers.Authorization = `Token ${token}`;
                    const interval = setInterval(() => {
                        clearInterval(interval);
                        resolve(session(error.config));
                    }, 500);
                });
            } else {
                await store.dispatch('auth/clearToken');
                console.info('use - login - 2');
                return router.push('/login');
            }
        } else if (error.response && error.response.status === 403) {
            console.log('api status - 403');
            await router.push({name: 'error403'});
        } else if (error.response && error.response.status === 404) {
            console.log('api status - 404');
            // await router.push({name: '404'});
        }
        return Promise.reject(error);
    }
);


export default session;
