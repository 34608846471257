<template>
    <div
        v-if="isShowContent"
        id="page-container"
        :class="[
            'side-scroll',
            'page-container',
            'page-header-modern',
            'side-trans-enabled',
            {'sidebar-o': isShowSidebar, 'sidebar-h': !isShowSidebar},
            { 'aside-change-width': asideDragging }
        ]"
        :style="`--aside-width: ${asideWidth}px`"
        @mousemove="onAsideChangeWidth"
        @mouseup="onAsideChangeEnd"

    >
        <div class="wrapper">
            <aside
                v-show="isShowSidebar"
                class="aside hide-print-css"
            >
                <div class="aside-header">
                    <div class="aside-header__row">
                        <div class="aside-header__col --left">
                            <header-dropdown :is-aside-width="asideWidth"/>
                        </div>

                        <div class="aside-header__col --right">
                            <button
                                type="button"
                                :class="[
                                    'aside-burger',
                                    { 'is-active': isSidebarOpened }
                                ]"
                                @click="onSidebarOpen"
                            >
                                <i
                                    class="aside-burger__icon"
                                />
                            </button>
                        </div>
                    </div>
                </div>

                <sidebar
                    :class="[
                        'aside-sidebar',
                        { 'is-visible': isSidebarOpened }
                    ]"
                ></sidebar>

                <div
                    :class="['aside__strip', { 'is-visible': asideDragging }]"
                    @mousedown="onAsideChangeStart"
                ></div>
            </aside>

            <main class="main">
                <transition name="fade" mode="out-in">
                    <router-view :key="$route.fullPath"/>
                </transition>
            </main>
        </div>

        <!--<footer id="page-footer" class="opacity-0">
          <div class="content py-20 clearfix">
            <div class="float-left">
              <span class="font-w600" target="_blank">WikiWorks</span> &copy; <span class="js-year-copy"></span>
            </div>
          </div>
        </footer>-->

        <div class="info-cookie" v-if="!termsOfUse">
            <p class="info-cookie__text">
                WikiWorks использует cookie-файлы. С их помощью мы улучшаем работу нашего сайта и сервиса, а также повышаем
                качество Вашего пользовательского опыта.
                Пользуясь нашим сайтом и сервисом, Вы даете свое согласие на использование cookie-файлов.
            </p>
            <button
                class="btn info-cookie__btn"
                type="button"
                @click="acceptTermsOfUse"
            >
                Понятно
            </button>
        </div>
    </div>
</template>

<script>
    import session from './api/session';
    import { mapGetters, mapState } from 'vuex';
    import VueScreenSize from 'vue-screen-size';
    import Sidebar from './components/sidebar/Sidebar';
    import HeaderDropdown from './components/sidebar/header/HeaderDropdown';
    import '@/helpers/shepherdCancel.js';
    import store from "./store";

    export default {
        name: 'App',
        components: {
            Sidebar,
            HeaderDropdown
        },

        mixins: [VueScreenSize.VueScreenSizeMixin],

        data() {
            return {
                termsOfUse: true,
                sidebarOpened: false,
                asideWidth: 300,
                asideDragging: false
            }
        },

        async created() {
            await this.initializeApp();
            this.$store.dispatch('default_data/setRouteShown', true);
            this.asideWidth = localStorage.getItem('aside-width') || 300;
        },

        computed: {
            isError() {
                return !!this.$route.meta.isError;
            },

            isShowContent() {
                return this.$route.meta.requiresAuth !== undefined && this.isRouteShown;
            },

            isShowSidebar() {
                return !this.isError && this.isAuthenticated && this.$route.meta.title !== 'Мастер настройки портала';
            },

            ...mapGetters('default_data', [
                'current_user', 'domain', 'onWizard', 'isRouteShown'
            ]),

            ...mapGetters('auth', [
                'isAuthenticated'
            ]),

            ...mapState('sidebar', [
                'isSidebarOpened'
            ]),

            ...mapGetters('sidebar', [
                'currentDomain'
            ])
        },

        methods: {
            logout() {
                this.$store.dispatch('auth/logout').then(() => this.$router.push('/login'))
            },

            acceptTermsOfUse() {
                this.$cookies.set('terms_of_use', true);
                this.termsOfUse = true;
            },

            async initializeApp() {
                try {
                    await session.get('/api/v1/main');
                    await this.$store.dispatch('auth/initialize');

                    if (this.isAuthenticated && !this.isError) {
                        await Promise.all([
                            this.$store.dispatch('default_data/initialize'),
                            this.$store.dispatch('navigation/initialize'),
                            this.$store.dispatch('settings/setPortalInfo'),
                            this.$store.dispatch('settings/setPaymentDetail')
                        ]);
                    }

                    this.termsOfUse = this.$cookies.get('terms_of_use');

                    if (this.onWizard) {
                        await this.$router.push('/start');
                    }
                } catch (e) {
                    console.error(e)
                    if (e.response) console.log('e.response', e.response)
                    if (e.response && e.response.status) console.log('e.response.status', e.response.status)
                    if (e.response && e.response.status === 403) {
                        await this.$router.push({ name: 'error403' })
                    }
                }
            },

            onAsideChangeStart(event) {
                this.asideDragging = true
                document.body.classList.add('cursor-col-resize')
            },

            onAsideChangeEnd() {
                this.asideDragging = false
                document.body.classList.remove('cursor-col-resize')
            },

            onAsideChangeWidth(event) {
                if (this.asideDragging === true) {
                    if (event.clientX <= 780 && event.clientX >= 280) {
                        this.asideWidth = event.clientX
                        localStorage.setItem('aside-width', this.asideWidth)
                    }
                }
            },

            onSidebarOpen() {
                this.$store.dispatch('sidebar/setSidebarOpened', !this.isSidebarOpened);
            }
        }
    }
</script>

<style lang="scss">
    @import "#sass/v-style";

    .page-container {
        &.aside-change-width {
            cursor: col-resize;
        }
    }

    .wrapper {
        display: flex;
        @media screen and (max-width: 1000px) {
            flex-direction: column;
        }
    }

    .aside {
        top: 0px;
        position: sticky;

        display: flex;
        flex-direction: column;
        z-index: 999;
        overflow: hidden;
        background-color: var(--sidebar-bg);
        padding: $sidebar-padding-top 0px 0px;

        @media screen and (min-width: 1001px) {
            padding-right: 3px;
            height: 100vh;
            width: var(--aside-width);
            min-width: var(--aside-width);
            background-color: var(--sidebar-bg);
        }

        @media screen and (max-width: 1000px) {
            width: 100%;
            padding: 7px 12px;
        }

        &__strip {
            top: 0px;
            right: 0px;
            bottom: 0px;
            position: absolute;
            user-select: none;
            cursor: col-resize;
            opacity: 0;
            width: 3px;
            background-color: $gold-main;
            &:hover {
                opacity: 1;
            }

            &.is-visible {
                opacity: 1;
            }
        }

        &-header {
            $header-block: &;
            margin-bottom: 30px;

            @media screen and (min-width: 1001px) {
                padding: 0px $sidebar-padding-left;
            }

            @media screen and (max-width: 1000px) {
                margin: 0px;
            }

            &__row {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            &__col {
                min-width: 0px;
                @media screen and (min-width: 1001px) {
                    flex-grow: 1;
                }

                & + #{$header-block}__col {
                    margin-left: 10px;
                }

                &.--right {
                    display: none;
                    min-width: 30px;
                    @media screen and (max-width: 1000px) {
                        display: inline-block;
                    }
                }
            }
        }

        &-sidebar {
            flex: 1 0 auto;
        }

        &-burger {
            cursor: pointer;
            border: 0px;
            background-color: transparent;
            &__icon {
                @include icon($sidebar-burger, 18px);
                .is-active & {
                    background-image: $sidebar-cross;
                }
            }
        }
    }

    .main {
        flex-grow: 1;
        min-width: 0px;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
    .sidebar-h {
        padding-left: 0 !important;
    }
    .vd-picker__input-clear{
        position: relative;
        top: 5px;
        left: 10px;
    }
    .form-control .vd-picker__input input{
        min-width: 92px;
    }

    @media screen and (max-height: 760px) {
        .vd-menu__content.vd-menu__content--active.vd-menu__content--fixed{
            position: absolute;
            top: calc(50% - 187px) !important;
        }
    }
</style>
