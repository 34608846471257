import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';

import auth from './auth';
import password from './password';
import signup from './signup';
import default_data from './default_data';
import navigation from './navigation';
import sidebar from './sidebar';
import settings from './settings';
import staff_month from './staff_month';
import wrapper_materials from './wrapper_materials';
import knowledge_base from './knowledge_base';
import url_login from './url_login';
import documents_data from './documents_data';
const debug = process.env.NODE_ENV !== 'production';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        knowledge_base,
        wrapper_materials,
        staff_month,
        settings,
        sidebar,
        navigation,
        default_data,
        auth,
        password,
        signup,
        url_login,
        documents_data
    },
    strict: debug,
    plugins: debug ? [createLogger()] : [],
});
