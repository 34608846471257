import session from '../api/session';
import {
    WRAPPER_MATERIALS,
    RECOMMEND_MATERIALS
} from './types';

const state = {
    favourites: {
        count: 0,
        list: []
    },
    old: {
        count: 0,
        list: []
    },
    learning: {
        count: 0,
        list: []
    },
    recommended: {
        count: 0,
        list: []
    }
};

const mutations = {
    [WRAPPER_MATERIALS](state, data) {
        state.favourites = data.favourites;
        state.learning = data.learning;
        state.old = data.old;
        state.learning = data.learning;
    },

    [RECOMMEND_MATERIALS](state, data) {
        state.recommended = {
            count: data.length,
            list: data
        };
    }
};

const actions = {
    initialize({ commit }) {
        return session.get('/api/v1/dashboard-widget/materials/')
            .then(({ data }) => commit(WRAPPER_MATERIALS, data));
    },

    initRecommendMaterials({ commit }) {
        return session.get('/api/v1/material/recommendations/')
            .then((req) => commit(RECOMMEND_MATERIALS, req.data));
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters: {},
    actions
};
