var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isShowContent)?_c('div',{class:[
        'side-scroll',
        'page-container',
        'page-header-modern',
        'side-trans-enabled',
        {'sidebar-o': _vm.isShowSidebar, 'sidebar-h': !_vm.isShowSidebar},
        { 'aside-change-width': _vm.asideDragging }
    ],style:(("--aside-width: " + _vm.asideWidth + "px")),attrs:{"id":"page-container"},on:{"mousemove":_vm.onAsideChangeWidth,"mouseup":_vm.onAsideChangeEnd}},[_c('div',{staticClass:"wrapper"},[_c('aside',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowSidebar),expression:"isShowSidebar"}],staticClass:"aside hide-print-css"},[_c('div',{staticClass:"aside-header"},[_c('div',{staticClass:"aside-header__row"},[_c('div',{staticClass:"aside-header__col --left"},[_c('header-dropdown',{attrs:{"is-aside-width":_vm.asideWidth}})],1),_c('div',{staticClass:"aside-header__col --right"},[_c('button',{class:[
                                'aside-burger',
                                { 'is-active': _vm.isSidebarOpened }
                            ],attrs:{"type":"button"},on:{"click":_vm.onSidebarOpen}},[_c('i',{staticClass:"aside-burger__icon"})])])])]),_c('sidebar',{class:[
                    'aside-sidebar',
                    { 'is-visible': _vm.isSidebarOpened }
                ]}),_c('div',{class:['aside__strip', { 'is-visible': _vm.asideDragging }],on:{"mousedown":_vm.onAsideChangeStart}})],1),_c('main',{staticClass:"main"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view',{key:_vm.$route.fullPath})],1)],1)]),(!_vm.termsOfUse)?_c('div',{staticClass:"info-cookie"},[_c('p',{staticClass:"info-cookie__text"},[_vm._v(" WikiWorks использует cookie-файлы. С их помощью мы улучшаем работу нашего сайта и сервиса, а также повышаем качество Вашего пользовательского опыта. Пользуясь нашим сайтом и сервисом, Вы даете свое согласие на использование cookie-файлов. ")]),_c('button',{staticClass:"btn info-cookie__btn",attrs:{"type":"button"},on:{"click":_vm.acceptTermsOfUse}},[_vm._v(" Понятно ")])]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }