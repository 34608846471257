import Vue from 'vue';
import {ValidationProvider, extend, ValidationObserver, validate, setInteractionMode} from 'vee-validate';
import { required, ext, is, numeric, min_value, alpha_dash, alpha_spaces, email, regex, confirmed } from 'vee-validate/dist/rules';

setInteractionMode('eager');

// Add custom rule.
// extend('secret', {
//   validate: value => value === 'example',
//   message: 'This is not the magic word'
// });
extend('required', {
  ...required,
  message: 'Обязательное поле.'
});
extend('accepted', {
    validate(value) {
        return value === true;
    },
    message: 'Примите условия договора.'
});
extend('ext', {
  ...ext,
  message: '{_field_}.'
});
extend('numeric', {
  ...numeric,
    message: '{_field_}.'
});
extend('min_value', {
  params: ['min'],
  validate(value, { min }) {
    return value >= Number(min);
  },
  message: "Введите значение больше {min}"
});
extend('min_amount', {
  params: ['min'],
  validate(value, { min }) {
    return value > Number(min);
  },
  message: "Введите значение больше {min}"
});
extend('not_null', {
    validate(value) {
        return value > 0;
    },
    message: "Введите значение больше 0"
});
extend('alpha_dash', {
  ...alpha_dash,
});
extend('alpha_spaces', {
  ...alpha_spaces,
});
extend('email', {
  ...email,
  message: 'Введите корректный E-mail.'
});
extend('regex', {
  ...regex,
  message: '{_field_}.'
});
extend('confirmed', {
  ...confirmed,
  message: '{_field_}.'
});
extend('min_length', {
  params: ['min'],
  validate(value, { min }) {
    return value.length >= Number(min);
  },
  message: 'Минимум символов: {min}'
});
extend('max_length', {
  params: ['max'],
  validate(value, { max }) {
    return value.length <= Number(max);
  },
  message: 'Максимум символов: {max}'
});
extend('max_size', {
    params: ['max'],
    validate(value, { max }) {
        return value.size <= Number(max);
    },
    message: 'Максимальный размер: {max}кб'
});
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
