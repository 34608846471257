<template>
    <el-dropdown
        class="header-dropdown"
        trigger="click"
        @visible-change="(status) => isVisible = status"
    >
        <div
            :class="[
                'header-dropdown__trigger',
                'el-dropdown-link',
                { 'is-visible': isVisible }
            ]"
        >
            <company
                class="header-dropdown__company"
                :current="true"
                :domain="currentDomain"
            />

            <svg
                class="header-dropdown__arrow"
                width="9"
                height="6"
                viewBox="0 0 9 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M1 1L4.5 4.5L8 1" stroke="white" stroke-width="1.5"/>
            </svg>
        </div>

        <el-dropdown-menu
            slot="dropdown"
            class="header-dropdown__menu"
            :style="`--aside-width: ${asideWidth}px`"
        >
            <el-dropdown-item class="header-dropdown__item --exclude">
                <companies/>
            </el-dropdown-item>

            <el-dropdown-item
                v-if="current_user.is_admin"
                class="header-dropdown__item --exclude"
            >
                <balance/>
            </el-dropdown-item>

            <el-dropdown-item class="header-dropdown__item">
                <user/>
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'HeaderDropdown',

        components: {
            Companies: () => import('./companies/Companies'),
            Company: () => import('./companies/Company'),
            Balance: () => import('./balance/Balance'),
            User: () => import('./user/User')
        },

        computed: {
            ...mapGetters('sidebar', ['currentDomain']),
            ...mapGetters('default_data', ['current_user'])
        },

        props: {
            isAsideWidth: {
                default: 300
            }
        },

        data() {
            return {
                isVisible: false,
                asideWidth: this.isAsideWidth
            }
        },

        watch: {
            isAsideWidth: {
                handler: function(val, oldVal) {
                    this.asideWidth = val;
                },
                deep: true
            }
        }
    };
</script>

<style lang="scss">
    @import "#sass/v-style";

    .header-dropdown {
        $header-block: &;

        &__trigger {
            display: flex;
            align-items: center;
            cursor: pointer;
            .company__name {
                color: #fff;
            }

            &.is-visible {
                .company {
                    &__name {
                        @media screen and (min-width: 1001px) {
                            color: #000;
                        }
                    }

                    &__logo {
                        &-img {
                            filter: drop-shadow(0px 2px 3px #6d6d6d);
                        }
                    }
                }
            }
        }

        &__company {
            flex-grow: 1;
        }

        &__arrow {
            min-width: 9px;
            margin: 0px 10px 0px 5px;
            transition: all $ease;
            .is-visible & {
                transform: rotate(180deg);
                path {
                    @media screen and (min-width: 1001px) {
                        stroke: #000;
                    }
                }
            }
        }

        &__menu {
            width: 100%;
            border-radius: 0 0 5px 5px;

            @media screen and (min-width: 1001px) {
                max-width: calc(var(--aside-width) - 13px);
            }

            @media screen and (max-width: 1000px) {
                left: 0px !important;
                right: 0px !important;
                max-width: inherit;
                border-bottom: 2px solid;
                .company {
                    &__name {
                        color: #fff;
                    }
                }
            }
        }

        &__item {
            &:not(.--exclude) {
                padding: 15px 22px;
            }
        }

        &__trigger.is-visible,
        &__menu {
            background-color: var(--dropdown-opened-bg);
        }
    }
</style>
