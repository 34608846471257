import session from '../api/session';
import {
    SET_PORTAL_INFO,
    SET_RATES,
    SET_PAYMENT_DETAIL,
    SETTINGS_FORM
} from './types';

const state = {
    portalInfo: {},
    rates: [],
    paymentDetail: {},
    settingsForm: {}
};

const mutations = {
    [SET_PORTAL_INFO](state, data) {
        state.portalInfo = data;
    },
    [SET_RATES](state, data) {
        state.rates = data;
    },
    [SET_PAYMENT_DETAIL](state, data) {
        state.paymentDetail = data;
    },
    [SETTINGS_FORM](state, data) {
        state.settingsForm = data;
    }
};

const actions = {
    initialize({ commit }) {
        return session.get('/api/v1/settings/1/')
            .then(({ data }) => commit(SETTINGS_FORM, data));
    },
    setPortalInfo({ commit }) {
        return session.get('/api/v1/rate/current_rate/')
            .then(({ data }) => commit(SET_PORTAL_INFO, data));
    },
    setRates({ commit }) {
        return session.get('/api/v1/rate/')
            .then(({ data }) => commit(SET_RATES, data));
    },
    setPaymentDetail({ commit, rootState }) {
        const user = rootState.default_data.current_user;
        if (user && user.is_admin) {
            return session.get('/api/v1/rate/payment_detail/')
                .then(({ data }) => commit(SET_PAYMENT_DETAIL, data));
        }
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters: {
        paymentDetail: state => state.paymentDetail,
        portalInfo: state => state.portalInfo,
        balanceAndPayment: state => ({
            balance: state.portalInfo.balance,
            date_to: state.paymentDetail.date_to,
            is_lock: state.paymentDetail.is_lock
        }),
        selectedRate: state => state.rates.find(rate => rate.id === state.portalInfo.type)
    },
    actions
};
