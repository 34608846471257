import auth from '../api/auth';

import {
    ACTIVATION_BEGIN,
    ACTIVATION_CLEAR,
    ACTIVATION_FAILURE,
    ACTIVATION_SUCCESS,
} from './types';

export default {
    namespaced: true,
    state: {
        activationCompleted: false,
        activationError: false,
        activationLoading: false
    },
    actions: {
        activateAccount({commit}, {key}) {
            commit(ACTIVATION_BEGIN);
            return auth.verifyAccountEmail(key)
                .then(() => commit(ACTIVATION_SUCCESS))
                .catch(() => commit(ACTIVATION_FAILURE));
        },
        clearActivationStatus({commit}) {
            commit(ACTIVATION_CLEAR);
        },
    },
    mutations: {
        [ACTIVATION_BEGIN](state) {
            state.activationLoading = true;
        },
        [ACTIVATION_CLEAR](state) {
            state.activationCompleted = false;
            state.activationError = false;
            state.activationLoading = false;
        },
        [ACTIVATION_FAILURE](state) {
            state.activationError = true;
            state.activationLoading = false;
        },
        [ACTIVATION_SUCCESS](state) {
            state.activationCompleted = true;
            state.activationError = false;
            state.activationLoading = false;
        }
    },
};
