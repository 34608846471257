const initialState = {
   urlLogin: "",
};
const getters = {
    getUrlLogin: state => {
        return state.urlLogin;
    },
}
const mutations = {
    setUrlLogin(state, data) {
        state.urlLogin = data.url;
    },
}
export default {
    namespaced: true,
    state: initialState,
    getters,
    mutations,
};
