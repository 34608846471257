document.addEventListener('DOMContentLoaded', () => {
    document.addEventListener('click', evt => {
        if(evt.target != document.querySelector('.shepherd-content') || document.querySelector('.shepherd-button')) {
            document.querySelectorAll('.shepherd-element').forEach(n => n.style.opacity = 0);
        }});

    if (document.querySelector('.sidebar__nav-link')) {
        document.querySelectorAll('.sidebar__nav-link').forEach(n => n.addEventListener('click', () => {
            document.querySelector('.shepherd-element').style.opacity = 0;
        }))
    };
});
