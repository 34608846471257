import session from '../api/session'
import {
    DOCUMENTS,
    ADD_DOCUMENTS
} from './types'

const state = {
    documentItems: {
        rubrics: [],
        list: [],
        all: []
    }
}

const mutations = {
    [DOCUMENTS](state, data) {
        if (data.rubrics) state.documentItems.rubrics = data.rubrics
        if (data.list) state.documentItems.list = data.list

        state.documentItems.all = state.documentItems.rubrics.concat(state.documentItems.list)
    },
    [ADD_DOCUMENTS](state, data) {
        const rubrics = data.rubrics || []
        const list = data.list || []

        state.documentItems.rubrics = state.documentItems.rubrics.concat(rubrics)
        state.documentItems.list = state.documentItems.list.concat(list)
        state.documentItems.all = state.documentItems.rubrics.concat(state.documentItems.list)
    }
}

const actions = {
    initialize({ commit }) {
        return session
            .get('/api/v1/rubric_document/', {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json'
                }
            })
            .then(({ data }) => commit(DOCUMENTS, data))
    },

    setDocuments({ commit }, data) {
        commit(DOCUMENTS, data)
    },

    addDocuments({ commit }, data) {
        commit(ADD_DOCUMENTS, data)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters: {
        documentItems: state => state.documentItems
    },
    actions
}
