import session from '../api/session';
import {
    SET_ALL_STAFF,
    SET_RECOMMENDATION_STAFF,
    SET_RECOMMENDATION_ADMIN,
    SET_STAFF_MONTH
} from './types';

const state = {
    // AllStaff - все сотрудники отделов/компании
    // RecommendationStaff - сохранённые рекоммендации у начальника и выбранные пользователи у админа
    // RecommendationAdmin - список предложенных пользователей у админа
    // StaffMonth - Сотрудники месяца
    allStaff: [],
    recommendationStaff: [],
    recommendationAdmin: [],
    staffMonth: []
};

const mutations = {
    [SET_ALL_STAFF](state, data) {
        state.allStaff = data;
    },
    [SET_RECOMMENDATION_STAFF](state, data) {
        state.recommendationStaff = data;
    },
    [SET_RECOMMENDATION_ADMIN](state, data) {
        state.recommendationAdmin = data;
    },
    [SET_STAFF_MONTH](state, data) {
        state.staffMonth = data;
    }
};

const actions = {
    setAllStaff({commit}) {
        return session.get('/api/v1/employees-month/employees/')
            .then(({data}) => commit(SET_ALL_STAFF, data))
    },
    setRecommendationStaff({commit}) {
        return session.get('/api/v1/employees-month/save-recommendation/')
            .then(({data}) => commit(SET_RECOMMENDATION_STAFF, data))
    },
    setRecommendationAdmin({commit}) {
        return session.get('/api/v1/employees-month/recommendation/')
            .then(({data}) => commit(SET_RECOMMENDATION_ADMIN, data))
    },
    setStaffMonth({commit}) {
        return session.get('/api/v1/employees-month/')
            .then(({data}) => commit(SET_STAFF_MONTH, data))
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters: {},
    actions
};
